import React from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  @media screen and (min-width: ${props => props.theme.sizes.maxWidth}) {
    padding: 3em 0 0 1.5em;
    position: absolute;
    top: 0;
    left: 0;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  li {
    line-height: 1.5em;
  }

  a {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`

const Title = styled.h4`
  line-height: 2em;
  font-weight: 600;
`

const FeaturedPosts = ({ posts }) => {
  return (
    <Wrapper>
      <Title>Featured Posts</Title>
      <ul>
        {posts.map(post => (
          <li key={post.title}>
            <a href={`/${post.node_locale}/blog/${post.slug}`}>
              {post.title}
            </a>
          </li>
        ))}
      </ul>
    </Wrapper>
  )
}

export default FeaturedPosts;