import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageBody from '../components/PageBody';
import Helmet from 'react-helmet'
import Container from '../components/Container'
import FeaturedPosts from '../components/FeaturedPosts'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Index = ({ data, pageContext }) => {
  const homePage = data.contentfulPage
  const { featuredPosts } = pageContext
  const { body } = homePage
  
  return (
    <Layout>
      <SEO postNode={homePage} pageSEO/>
        <Helmet>
          <title>{config.siteTitle}</title>
        </Helmet>
      <Container>
        <FeaturedPosts posts={featuredPosts} />
        <PageBody body={body} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($locale: String!) {
    contentfulPage(
      node_locale: { eq: $locale}
      slug: { eq: "home"}
    ) {
      title
      slug
      node_locale
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default Index
